@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Segoe UI Symbol', 'Segoe UI Emoji',
'Apple Color Emoji', Roboto, Helvetica, Arial, sans-serif !default;
$code-font-family: 'Menlo', 'Inconsolata', 'Consolas', 'Roboto Mono', 'Ubuntu Mono', 'Liberation Mono', 'Courier New',
monospace;
$base-font-size: 16px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$table-text-align: left !default;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

$on-medium: $on-palm !default;
$on-large: $on-laptop !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
// Notice the following mixin uses max-width, in a deprecated, desktop-first
// approach, whereas media queries used elsewhere now use min-width.
@mixin media-query($device) {
	@media screen and (max-width: $device) {
		@content;
	}
}

@mixin relative-font-size($ratio) {
	font-size: #{$ratio}rem;
}

// Import pre-styling-overrides hook and style-partials.
@import 	// Hook to override predefined variables.
'minima/snailedit-variables',
'custom-variables',
	// Hook to override predefined variables.
'minima/base',
	// Defines element resets.
'minima/layout',
	// Defines structure and style based on CSS selectors.
	// Hook to override existing styles.
'minima/snailedit-styles',
	// Hook to override existing styles.
'custom-styles'
;
