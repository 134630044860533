$snail-brand-color: #41BA63;
$lm-link-base-color: darken($snail-brand-color, 25%);
$dm-link-base-color: brand-color;
$secondary-color: #38424D;
$site-header: #41BA63;
:root {
	--secondary-color: #{darken($secondary-color, 25%)};
	--minima-link-base-color: #{$lm-link-base-color};
	--minima-link-visited-color: #{$lm-link-base-color};
	--minima-link-hover-color: #{$lm-link-base-color};

	@media (prefers-color-scheme: dark) {
		--secondary-color: #{$secondary-color};
		--minima-link-base-color: #{$snail-brand-color};
		--minima-link-visited-color: #{$snail-brand-color};
		--minima-link-hover-color: #{$snail-brand-color};
	}
}

$secondary-color: var(--secondary-color);