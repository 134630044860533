.site-title {
  font-weight: 600;
}

.post {
  .section {
    column-gap: 2em;
    grid-auto-columns: 1fr;

    .section-text {
      ul {
        margin-left: unset;
        list-style: none;

        li {
          padding-bottom: 0.5em;
        }
      }
    }
  }
}